<template>
  <!-- filter area -->
  <div class="p-fluid p-grid p-jc-end print-hidden">
    <!-- right -->
    <div class="p-col-12 p-sm-3" v-if="userLevel == 'AC'">
      <Dropdown
        v-model="areaId"
        :options="areas"
        optionLabel="areaName"
        optionValue="codeId"
        placeholder="กรองข้อมูล พื้นที่"
        :filter="true"
        @change="fetchData"
      />
    </div>
    <div class="p-col-11 p-sm-2">
      <router-link to="/dashboard">
        <Button label="กลับสู่หน้าหลัก" icon="pi pi-arrow-left"
      /></router-link>
    </div>
    <div class="p-col-1">
      <Button icon="pi pi-print" @click="print" />
    </div>
  </div>
  <!-- / filter area -->
  <Fieldset>
    <template #legend>
      <i class="pi pi-check" style="fontsize: 2rem"></i> อาชีพ
    </template>
    <div>
      การวิเคราะห์ข้อมูลในส่วนนี้จะเป็นการฉายภาพสถานะของอายุของผู้สูงอายุในปัจจุบัน
      ตามอาชีพนำร่องที่เก็บข้อมูลในโครงการ โดยการวิเคราะห์ในส่วนนี้
      จะเป็นการนำเสนอ
    </div>
    <div>1.ข้อมูลแสดงจำนวนผู้สูงอายุที่เลือกอาชีพที่ต้องการทำในอนาคต</div>
    <div>2.ข้อมูลเรื่องอาชีพอื่นๆ นอกเหนือจาก 7 อาชีพ</div>
    <div>3.ข้อมูลความคิดเห็นเรื่องเหตุผลที่ไม่เลือก 7 อาชีพหลัก</div>
  </Fieldset>
  <Fieldset legend="กราฟแสดงจำนวนผู้สูงอายุที่เลือกอาชีพที่ต้องการทำในอนาคต">
    <div ref="chartOccupation" :style="graphStyle"></div>
    <DividerWord :content="words['OCCU01']" />
  </Fieldset>
  <Fieldset>
    <template #legend>
      <i class="pi pi-check" style="fontsize: 2rem"></i> อาชีพอื่นๆ นอกเหนือจาก
      7 อาชีพ
    </template>
    <Fieldset :legend="images['1_career_need_oth']">
      <div class="p-text-center">
        <img
          :src="plots['1_career_need_oth']"
          :alt="images['1_career_need_oth']"
          class="img-responsive"
        />
      </div>
      <DividerWord :content="words['OCCU02']" />
    </Fieldset>
    <Fieldset :legend="images['2_career_need_oth']">
      <div class="p-text-center">
        <img
          :src="plots['2_career_need_oth']"
          :alt="images['2_career_need_oth']"
          class="img-responsive"
        />
      </div>
      <DividerWord :content="words['OCCU03']" />
    </Fieldset>
    <Fieldset :legend="images['3_career_need_oth']">
      <div class="p-text-center">
        <img
          :src="plots['3_career_need_oth']"
          :alt="images['3_career_need_oth']"
          class="img-responsive"
        />
      </div>
      <DividerWord :content="words['OCCU04']" />
    </Fieldset>
  </Fieldset>

  <Fieldset>
    <template #legend>
      <i class="pi pi-check" style="fontsize: 2rem"></i> เหตุผลที่ไม่เลือก 7
      อาชีพหลัก
    </template>
    <Fieldset :legend="images['1_career_need_reason']">
      <div class="p-text-center">
        <img
          :src="plots['1_career_need_reason']"
          :alt="images['1_career_need_reason']"
          class="img-responsive"
        />
      </div>
      <DividerWord :content="words['OCCU05']" />
    </Fieldset>
    <Fieldset :legend="images['2_career_need_reason']">
      <div class="p-text-center">
        <img
          :src="plots['2_career_need_reason']"
          :alt="images['2_career_need_reason']"
          class="img-responsive"
        />
      </div>
      <DividerWord :content="words['OCCU06']" />
    </Fieldset>
    <Fieldset :legend="images['3_career_need_reason']">
      <div class="p-text-center">
        <img
          :src="plots['3_career_need_reason']"
          :alt="images['3_career_need_reason']"
          class="img-responsive"
        />
      </div>
      <DividerWord :content="words['OCCU07']" />
    </Fieldset>
  </Fieldset>
  <MoveToTopPage />
</template>

<script>
import axios from 'axios'
import * as _ from 'lodash'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'

import { imageEncode } from '../../Utility'
import { mixins } from '../Mixins'

import MoveToTopPage from '../../components/MovetoTop'
import DividerWord from './DividerWord'

// Themes begin
am4core.useTheme(am4themes_animated)
// Themes end

export default {
  mixins: [mixins],
  layout: 'Private',
  data() {
    return {
      areaId: 'ALL',
      series: {
        occupationSelect: null
      },
      plots: {
        '1_career_need_oth': null,
        '1_career_need_reason': null,
        '2_career_need_oth': null,
        '2_career_need_reason': null,
        '3_career_need_oth': null,
        '3_career_need_reason': null
      },
      images: {
        '1_career_need_oth': 'ช่วงอายุ 60-65 ปี',
        '1_career_need_reason': 'ช่วงอายุ 60-65 ปี',
        '2_career_need_oth': 'ช่วงอายุ 66-70 ปี',
        '2_career_need_reason': 'ช่วงอายุ 66-70 ปี',
        '3_career_need_oth': 'ช่วงอายุ > 70 ปี',
        '3_career_need_reason': 'ช่วงอายุ > 70 ปี'
      }
    }
  },
  computed: {
    graphStyle() {
      return {
        height: '500px'
      }
    },
    userLevel() {
      const { codeId, userLevel } = this.$store.getters['oauth/session']
      return userLevel
    },
    userArea() {
      const { codeId, userLevel, area } = this.$store.getters['oauth/session']
      if (userLevel == 'AC') {
        return this.areaId == 'ALL' ? null : this.areaId
      } else {
        return area?.codeId
      }
    },
    areas() {
      return this.$store.getters['area/getAreasHasAllLabel']
    }
  },
  watch: {
    series: {
      handler(newVal, oldVal) {
        console.log('newVal ::==', newVal)
        const { occupationSelect } = newVal
        this.pieOccupation(occupationSelect)
      },
      deep: true
    }
  },
  async created() {
    try {
      await this.$store.dispatch('area/fetchAreasOrderNameTh')
      this.fetchData()
    } catch (error) {
      this.$toast.add({
        severity: 'error',
        summary: 'เกิดข้อผิดพลาดจากระบบ',
        detail: 'ระบบไม่สามารถให้บริการได้ ขออภัยในความไม่สะดวก',
        life: 3000
      })
    }
  },
  mounted() {
    this.pieOccupation()
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch('word/fetchGraphWords', {
        areaId: this.userArea
      })
      //**************** get graph api*************** */
      const data = await this.$store.dispatch(
        'pivot/fetchGraphSeriesAnalytise',
        {
          url: 'OCCUPATION',
          areaId: this.userArea
        }
      )
      console.log('data ::==', data)
      this.series = data
      this.getWordcloudGraph()
      //**************** get graph api*************** */
    },
    getWordcloudGraph() {
      const { VUE_APP_ML_ENDPOINT } = process.env
      let delay = 500
      let idx = 0
      Object.keys(this.images).forEach((name) => {
        setTimeout(
          async () => {
            this.plots[name] = 'img/wordcloud/'+this.areaId+'/'+name+'.png?'+new Date().getTime();
          },
          idx == 0 ? 0 : delay + idx * 500
        )
      })
      /*Object.keys(this.images).forEach((name) => {
        setTimeout(
          async () => {
            const url = `${VUE_APP_ML_ENDPOINT}/wordcloud/image` // ?area_id=ALL&name=sum_sts
            const { data: blob } = await axios({
              url: url,
              method: 'GET',
              params: {
                area_id: this.areaId,
                name: name
              },
              responseType: 'arraybuffer',
              headers: {
                'Cache-Control': 'no-cache',
                Pragma: 'no-cache',
                Expires: '0'
              }
            })
            this.plots[name] = imageEncode(blob)
          },
          idx == 0 ? 0 : delay + idx * 500
        )
      })*/
    },
    pieOccupation(occupationSelect) {
      const chart = am4core.create(
        this.$refs.chartOccupation,
        am4charts.PieChart3D
      )
      chart.hiddenState.properties.opacity = 0 // this creates initial fade-in

      chart.legend = new am4charts.Legend()
      chart.legend.valueLabels.template.disabled = true

      chart.data = [
        {
          country: 'ค้าขาย',
          litres: occupationSelect?.sum01
        },
        {
          country: 'ทำไร่/ทำสวน',
          litres: occupationSelect?.sum02
        },
        {
          country: 'กรีดยาง',
          litres: occupationSelect?.sum03
        },
        {
          country: 'ทำสวนผลไม้',
          litres: occupationSelect?.sum04
        },
        {
          country: 'ทำนา',
          litres: occupationSelect?.sum05
        },
        {
          country: 'รับดูแลเด็กเล็ก 0-3 ขวบ',
          litres: occupationSelect?.sum06
        },
        {
          country: 'แนะนำขายผลิตภัณฑ์ในร้านค้าและในระบบออนไลน์',
          litres: occupationSelect?.sum07
        },
        {
          country: 'อื่นๆ',
          litres: occupationSelect?.sum08
        }
      ]

      const series = chart.series.push(new am4charts.PieSeries3D())
      series.colors.step = 3
      series.dataFields.value = 'litres'
      series.dataFields.category = 'country'
    }
  },
  components: {
    MoveToTopPage,
    DividerWord
  }
}
</script>
<style lang="scss" scoped>
@media only screen and (max-width: 768px) {
  .img-responsive {
    width: 100%;
  }
}
@media only screen and (min-width: 769px) {
  .img-responsive {
    width: 65%;
  }
}
</style>
